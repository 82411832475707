import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import SkunkGame from "../../../components/skunkgame";
export const _frontmatter = {
  "templateKey": "example",
  "type": "challenge",
  "title": "Skunk Game",
  "slug": "/portfolio/skunkgame",
  "author": "Gabriel Kirkley",
  "image": "./double-six-dice.jpg",
  "using": "Java / Javascript",
  "source": "https://github.com/gdgkirkley/bcit-comp1451-skunk",
  "published": true,
  "date": "2020-06-28T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`SKUNK is a console game written in Java built for BCIT's Introduction to
Software Development II course meant to demonstrate our understanding of
polymorphism and inheritance.`}</p>
    <p>{`You can see the original Java code
`}<a parentName="p" {...{
        "href": "https://github.com/gdgkirkley/bcit-comp1451-skunk"
      }}>{`here`}</a>{`. But I've rewritten it
in JS so that you can play it below!`}</p>
    <p>{`The fun part of rewriting the JS game was that the original Java code relied
heavily on while loops and iterators for the implementation. In JS, I had to
change all of that to callbacks to allow user input.`}</p>
    <p>{`I also built a new Render class with static methods to add new DOM nodes, rather
than using React for updating. The original game wrote to the Java console, and
the new implementation copies that pattern by successively adding new nodes to
the game div.`}</p>
    <SkunkGame mdxType="SkunkGame" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      